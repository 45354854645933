import { useState, memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import Avatar from 'barn/components/Avatar';
import Popover from 'barn/components/Popover';
import { ProgressBar } from 'barn/components/ProgressBar';
import SettingsIcon from 'barn/icons/Settings';
import LogoutIcon from 'barn/icons/Logout';
import CouponsIcon from 'barn/icons/Coupons';
import DocsIcon from 'barn/icons/Document';
import ChevronDownIcon from 'barn/icons/ChevronDown';
import Button from 'barn/components/Button';
import { Text } from 'barn/components/Text';
import Modal from 'barn/components/Modal/Modal';
import { HStack, Stack, VStack } from 'barn/components/Stack';
import Input from 'barn/components/form/input';
import TickCircle from 'barn/components/TickCircle';
import colors from 'barn/tokens/colors';
import Spinner from 'barn/components/Spinner';
import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';
import { getStorage } from 'src/lib/storage';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { useTranslation } from 'next-i18next';
import { handleRedirect } from 'src/lib/utils';
import Styled from './styled';
import { AppBridgeFullscreenButton } from './AppBridgeFullscreenButton';

const ProfileMenu = () => {
  const router = useRouter();
  const { user, logout } = useAuth();
  const {
    actions: { showPricing },
  } = useGlobalState('pricing');
  const {
    state: { consumption },
  } = useGlobalState('settings');
  const { t } = useTranslation('profile');

  const appBridgeConfig = useMemo(() => {
    const storage = getStorage();
    return storage.get('app_bridge_config');
  }, []);

  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  const subdomain = user && user.website && user.website.subdomain;
  const planName =
    user && user.website.website_plan && user.website.website_plan.name;
  const isPlatformBigCommerce = user.website.platform === 'bigcommerce';

  const websites =
    user.website.flags.enable_multistorelogin === 'disabled'
      ? []
      : [...(user.websites || [])].filter(
          website => website.subdomain !== subdomain,
        );

  const [isWebsiteSwitchModalOpen, setIsWebsiteSwitchModalOpen] =
    useState(false);
  const [selectedSubdomain, setSelectedSubdomain] = useState();
  const [filteredWebsites, setFilteredWebsites] = useState(websites);
  const [websiteSearchTerm, setWebsiteSearchTerm] = useState('');

  const websiteSelectHandler = newSubdomain => {
    setSelectedSubdomain(newSubdomain);
  };
  const onWebsiteSwitchModalCancel = () => {
    setSelectedSubdomain(null);
    setWebsiteSearchTerm('');
    setIsWebsiteSwitchModalOpen(false);
    setFilteredWebsites(websites);
  };
  const switchSubdomain = () => {
    if (!selectedSubdomain) {
      /* eslint-disable no-alert */
      window.alert('Please select a store first.');
      return;
    }
    const answer = window.confirm(
      `Are you sure you want to switch from store "${subdomain}" to store "${selectedSubdomain}"?`,
    );

    if (answer) {
      const storage = getStorage();
      storage.set('segmentdata', null);
      handleRedirect(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/${
          filteredWebsites.find(site => site.subdomain === selectedSubdomain)
            .platform
        }/pushowl/?subdomain=${selectedSubdomain}`,
      );
    }
  };

  const websiteSearchInputChangeHandler = (searchText = '') => {
    setWebsiteSearchTerm(searchText);
    if (!searchText) {
      setFilteredWebsites(websites);
      return;
    }

    setFilteredWebsites(
      websites.filter(website => {
        return (
          website.subdomain.indexOf(searchText.toLowerCase()) !== -1 ||
          website.domain.indexOf(searchText.toLowerCase()) !== -1
        );
      }),
    );
  };

  return (
    <>
      <Popover
        isVisible={isPopoverVisible}
        placement='bottomRight'
        setVisibility={setPopoverVisibility}
        overlay={
          <Styled.Popover>
            <Styled.Meta>
              <VStack gap={3}>
                <span className='websitename' title={subdomain}>
                  {subdomain}
                </span>
                <Text>
                  {user.isGILStore
                    ? `${(planName || '').split(' ')[0]} Group`
                    : planName}
                </Text>

                {planName === 'Free' && !isPlatformBigCommerce && (
                  <Button
                    variant='info'
                    onClick={() =>
                      showPricing({
                        utmMedium: 'profile_dropdown',
                      })
                    }
                    testHandle='profile-menu-upgrade-btn'
                  >
                    Upgrade
                  </Button>
                )}

                <HStack gap={1}>
                  <Text>
                    Credits{' '}
                    {!consumption.isFetching &&
                      `(${consumption.impressionsConsumed} / ${consumption.impressionsLimit} used)`}
                  </Text>

                  {consumption.isFetching && <Spinner small inline />}
                </HStack>

                <ProgressBar
                  width='100%'
                  max={consumption.impressionsLimit}
                  value={consumption.impressionsConsumed}
                />

                {/* <HStack gap={1}>
                  <Text variant='secondary'>
                    Impressions reset at{' '}
                    {!consumption.isFetching &&
                      format(new Date(consumption.resetDate), 'PPpp')}
                  </Text>

                  {consumption.isFetching && <Spinner small inline />}
                </HStack> */}
              </VStack>
            </Styled.Meta>
            <Styled.LinkList>
              <Styled.LinkListItem onClick={() => router.push('/settings')}>
                <SettingsIcon />
                <p>{t('settings')}</p>
              </Styled.LinkListItem>

              <Styled.LinkListItem
                as='a'
                href='https://docs.reachbee.com'
                target='_blank'
                rel='noreferrer'
              >
                <DocsIcon />
                <span>{t('documentation')}</span>
              </Styled.LinkListItem>

              {isShopifyEmbedded() ? (
                <>
                  <Styled.LinkListItem
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/login?${new URLSearchParams({
                          authToken: user.token,
                          subdomain,
                          platform: user.website.platform,
                          next: btoa(
                            JSON.stringify({
                              path: window.location.pathname,
                              params: {},
                            }),
                          ),
                        })}`,
                        '_blank',
                      );
                    }}
                  >
                    <LogoutIcon />
                    <span>{t('open_in_new_tab')}</span>
                  </Styled.LinkListItem>
                  {appBridgeConfig?.host && appBridgeConfig?.apiKey && (
                    <AppBridgeFullscreenButton />
                  )}
                </>
              ) : (
                <Styled.LinkListItem onClick={() => logout()} tabIndex={0}>
                  <LogoutIcon color={colors.reds[5]} />
                  <span>{t('logout')}</span>
                </Styled.LinkListItem>
              )}
            </Styled.LinkList>
          </Styled.Popover>
        }
      >
        <Styled.ProfileMenu
          data-test='profile-menu'
          onClick={e => {
            setPopoverVisibility(!isPopoverVisible);

            // Stop bubble up to Popover which triggers visibility close
            e.stopPropagation();
          }}
        >
          {user && user.website && user.website.company_logo && (
            <Avatar
              size='small'
              src={user.website && user.website.company_logo}
              alt='profile'
            />
          )}
          <p className='username'>
            {user && user.website && user.website.website_name}&nbsp;
            <ChevronDownIcon />
          </p>
        </Styled.ProfileMenu>
      </Popover>

      <Modal
        isOpen={isWebsiteSwitchModalOpen}
        onRequestClose={onWebsiteSwitchModalCancel}
      >
        <Modal.Header>
          <Stack gap={4} align='center'>
            <Text size={4} variant='primary'>
              Select a store to switch to
            </Text>
          </Stack>
        </Modal.Header>

        <Stack gap={7} direction='vertical'>
          {websites && websites.length > 5 ? (
            <Input
              name='website-search-term'
              onChange={websiteSearchInputChangeHandler}
              fullWidth
              placeholder='Search stores...'
              value={websiteSearchTerm}
            />
          ) : null}
          <div style={{ maxHeight: '360px', overflowY: 'auto' }}>
            {filteredWebsites &&
              filteredWebsites.map(website => {
                return (
                  <Styled.WebsiteItemButton
                    key={website.subdomain}
                    aria-selected={selectedSubdomain === website.subdomain}
                    disabled={subdomain === website.subdomain}
                    onClick={() => websiteSelectHandler(website.subdomain)}
                  >
                    <Stack gap={4} align='center'>
                      <div>
                        <TickCircle
                          done={selectedSubdomain === website.subdomain}
                        />
                      </div>
                      <div>
                        <img
                          src={website.company_logo}
                          width='44'
                          height='44'
                          style={{ background: '#D2DCE7', borderRadius: 4 }}
                          alt=''
                        />
                      </div>
                      <Stack gap={2} direction='vertical'>
                        <Text size={3} variant='primary'>
                          {website.subdomain}
                        </Text>
                        <Text size={1} variant='primary'>
                          {website.domain}
                        </Text>
                      </Stack>
                    </Stack>
                  </Styled.WebsiteItemButton>
                );
              })}

            {websites && websites.length && !filteredWebsites.length ? (
              <Text tag='p' size={2}>
                No stores found
              </Text>
            ) : null}
          </div>
        </Stack>

        <Modal.ActionBar
          onConfirm={switchSubdomain}
          onCancel={onWebsiteSwitchModalCancel}
          confirmText='Switch Store'
          confirmButtonDisabled={!selectedSubdomain}
        />
      </Modal>
    </>
  );
};

export default memo(ProfileMenu);
